exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-budget-tsx": () => import("./../../../src/pages/budget.tsx" /* webpackChunkName: "component---src-pages-budget-tsx" */),
  "component---src-pages-checklist-tsx": () => import("./../../../src/pages/checklist.tsx" /* webpackChunkName: "component---src-pages-checklist-tsx" */),
  "component---src-pages-domains-tsx": () => import("./../../../src/pages/domains.tsx" /* webpackChunkName: "component---src-pages-domains-tsx" */),
  "component---src-pages-features-accessibility-tsx": () => import("./../../../src/pages/features/accessibility.tsx" /* webpackChunkName: "component---src-pages-features-accessibility-tsx" */),
  "component---src-pages-features-adaptability-tsx": () => import("./../../../src/pages/features/adaptability.tsx" /* webpackChunkName: "component---src-pages-features-adaptability-tsx" */),
  "component---src-pages-features-domains-tsx": () => import("./../../../src/pages/features/domains.tsx" /* webpackChunkName: "component---src-pages-features-domains-tsx" */),
  "component---src-pages-features-email-tsx": () => import("./../../../src/pages/features/email.tsx" /* webpackChunkName: "component---src-pages-features-email-tsx" */),
  "component---src-pages-features-hosting-tsx": () => import("./../../../src/pages/features/hosting.tsx" /* webpackChunkName: "component---src-pages-features-hosting-tsx" */),
  "component---src-pages-features-local-tsx": () => import("./../../../src/pages/features/local.tsx" /* webpackChunkName: "component---src-pages-features-local-tsx" */),
  "component---src-pages-features-performance-tsx": () => import("./../../../src/pages/features/performance.tsx" /* webpackChunkName: "component---src-pages-features-performance-tsx" */),
  "component---src-pages-features-security-tsx": () => import("./../../../src/pages/features/security.tsx" /* webpackChunkName: "component---src-pages-features-security-tsx" */),
  "component---src-pages-features-seo-tsx": () => import("./../../../src/pages/features/seo.tsx" /* webpackChunkName: "component---src-pages-features-seo-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-tools-tsx": () => import("./../../../src/pages/tools.tsx" /* webpackChunkName: "component---src-pages-tools-tsx" */),
  "component---src-pages-wix-tsx": () => import("./../../../src/pages/wix.tsx" /* webpackChunkName: "component---src-pages-wix-tsx" */)
}

